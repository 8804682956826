import { UserData } from '@tm/types/common/app-api/auth'

export type CrispPushItem = string | number | Record<string, string> | undefined | CrispPushItem[]

export type CrispPush = (item: CrispPushItem) => void

export interface CrispWindow extends Window {
  $crisp?: CrispPushItem[]
}

const crispPush: CrispPush = (item: CrispPushItem) => {
  const crispWin = window as unknown as CrispWindow
  crispWin.$crisp = crispWin.$crisp || []
  crispWin.$crisp.push(item)
}

export const updateCrisp = (userData: UserData, push: CrispPush = crispPush) => {
  try {
    const { email, name, crispHash, organization } = userData
    if (!crispHash) return

    const {
      name: organization_name,
      id: organization_id,
      appSubscriptionInfo: { plan_id },
    } = userData.organization

    const plans = userData.organization.appSubscriptionInfo.subscription.data.plans || []
    const plan = plans.length ? plans.map(plan => plan.planId).join(', ') : plan_id

    push(['set', 'user:email', [email, crispHash]])

    if (name) push(['set', 'user:nickname', name])

    push([
      'set',
      'session:data',
      [
        [
          ['organization', organization_name],
          ['organization_id', organization_id],
          ['plan', plan],
          ['admin2_url', `https://admin2.trustmary.com/organization/${organization_id}`],
          ['admin_plan_url', `https://admin.trustmary.com/settings/plan?limit=1000&editId=${plan_id}`],
          ['ARR', userData.organization.ARR],
        ],
      ],
    ])

    const website = organization?.website

    push(['set', 'user:company', [organization_name, website ? { url: website } : undefined]])
  } catch (error) {
    console.log('Crisp error updateCrisp')
    console.log(error)
  }
}

export const resetCrisp = (push: CrispPush = crispPush) => {
  try {
    push(['do', 'session:reset'])
  } catch (error) {
    console.log('Crisp error resetCrisp')
    console.log(error)
  }
}
